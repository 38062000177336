import { db, dbInstance } from './firebase';
import firebase from 'firebase/app';
require('firebase/database');
require('firebase/storage');
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
// var proxy_server = 'http://127.0.0.1:8080/';
export function getDBRef(path) {
  console.log(path);
  return firebase.database().ref(`${path}`);
}

export function getpushkey() {
  return firebase.database().ref().child('Users').push().key;
}

export async function getSessionDetails() {
  let sessionID = await firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true);
  return sessionID;
}
export function getUserDetails() {
  return firebase.auth().currentUser;
}

export function getStorageRef(path) {
  var db = firebase.storage();
  return db.ref(path);
}

export function convertCollectionsSnapshotToMap(snapshots) {
  return snapshots.docs.reduce((accumulator, collection) => {
    accumulator[collection.id] = collection.data();
    return accumulator;
  }, {});
}
export async function getNewDocRef(collectionName) {
  return await db.collection(collectionName).doc();
}
export async function addDocument(collectionName, documentData) {
  return await db
    .collection(collectionName)
    .add(documentData)
    .then((docRef) => docRef.id);
}
export async function setDocument(collectionName, docRef, documentData) {
  return await db.collection(collectionName).doc(docRef).set(documentData);
}

export async function getDocuments(collectionName) {
  return await db
    .collection(collectionName)
    .get()
    .then((querySnapshot) =>
      querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
    );
}
export async function getDocumentsByQuery(collectionName, query) {
  return await db
    .collection(collectionName)
    .where(...query)
    .get()
    .then((querySnapshot) =>
      querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
    );
}

export async function deleteDocuments(collectionName) {
  const collectionRef = db.collection(collectionName);
  var batch = db.batch();
  await collectionRef
    .get()
    .then((querySnapshot) =>
      querySnapshot.docs.map((doc) => batch.delete(collectionRef.doc(doc.id)))
    );

  return await batch.commit().then(() => {});
}

export const addCollectionAndDocuments = async (
  collectionKey,
  objectsToAdd
) => {
  const collectionRef = db.collection(collectionKey);
  const batch = db.batch();
  objectsToAdd.forEach((obj) => {
    const newDocRef = collectionRef.doc();
    batch.set(newDocRef, obj);
  });
  return await batch.commit().then(() => {});
};
// const { title, items } = doc.data();
//     return {
//       routeName: encodeURI(title.toLowerCase()),
//       id: doc.id,
//       title,
//       items,
//     };
//   });
//
//   return transformedCollection.reduce((accumulator, collection) => {
//     accumulator[collection.title.toLowerCase()] = collection;
//     return accumulator;
//   }, {});
