
// const cors = require('cors');

var axios = require('axios');
// const path = require('path');
// const cwd = path.join(__dirname, '..');
// var proxy_server = Firebase.getEnv()!='DEVELOPMENT'?'https://proxy.dhruvaspace.com:8080/':'http://localhost:8080/' ;

// var proxy_server = 'http://127.0.0.1:8080/';
var proxy_server='https://proxy.dhruvaspace.xyz:1456/';

export default class SSCModel {

    static async get_AllStations(){
        var config = {
            method : 'GET',
            mode:  'no-cors',
            url : `${proxy_server}https://infinitydemo.sscspace.com/api/scheduler/v1/station`,
            headers :{
               'Content-Type':  'application/json;charset=UTF-8' ,
               'Authorization': 'Basic a3Jpc2huYXRlamFAZGhydXZhc3BhY2UuY29tOnghUzIjQ2VhYkQkcXVBUA==', 
            },
        };
        
        try {
            
            let dataMain=await axios(config);
            
        return dataMain
            
         } catch (error) {
                 let dataMain=[];
                 
        return dataMain
                 }
        
    }

    static async get_GroundStationDetails(stationId){
         var config = {
            method : 'GET',
            mode:  'no-cors',
            url : `https://infinitydemo.sscspace.com/api/scheduler/v1/station/${stationId}`,
            headers :{
                 'Content-Type': 'application/x-www-form-urlencoded' ,
                'Authorization': 'Basic a3Jpc2huYXRlamFAZGhydXZhc3BhY2UuY29tOnghUzIjQ2VhYkQkcXVBUA==', 
             },
         };
         let dataMain=await axios(config);
         
    }

    static async get_StationSatelliteAllDetails(stationId){
        var config = {
            method : 'GET',
            mode:  'no-cors',
            url : `${proxy_server}https://infinitydemo.sscspace.com/api/scheduler/v1/station/${stationId}/satellite`,
            headers :{
                'Content-Type': 'application/x-www-form-urlencoded' ,
               'Authorization': 'Basic a3Jpc2huYXRlamFAZGhydXZhc3BhY2UuY29tOnghUzIjQ2VhYkQkcXVBUA==', 
            },
        };
        try{
            let dataMain=await axios(config);
        
        return dataMain

        }
        
        catch (error) {
            let dataMain=[];
            
   return dataMain
            }
    }

    static async get_StationSatelliteDetails(stationId,satelliteId){
        var config = {
            method : 'GET',
            mode:  'no-cors',
            url : `${proxy_server}https://infinitydemo.sscspace.com/api/scheduler/v1/station/${stationId}/visibility/${satelliteId}`,
            headers :{
                 'Content-Type': 'application/x-www-form-urlencoded' ,
                'Authorization': 'Basic a3Jpc2huYXRlamFAZGhydXZhc3BhY2UuY29tOnghUzIjQ2VhYkQkcXVBUA==', 
            },
        };
        try{

            let dataMain=await axios(config);
        
        return dataMain

        }
        catch{
            let dataMain=[];
            
   return dataMain
        }
        
    }

    static get_StationSatelliteVisibilityStartEnd(stationId,satelliteId,StartTime,Endtime,onComplete){

    }

    static get_StationVisibilityAllStartEnd(stationId,StartTime,Endtime,onComplete){

    }

    static get_AllContactsForStation(stationId,onComplete){

    }

    static get_AllContactsForStation(stationId,cotactId,onComplete){

    }

    static set_ContactForSatellite(stationId,satelliteId,startTime,EndTime,ConfigurationId,onComplete){

    }

    static put_UpdateContact(stationId,contactId,onComplete){

    }

    static delete_Contact(stationId,contactId,onComplete){

    }
}