import v1actions from './actions';

const initState = {satellite_object:[],passPredicitonDropdown: [] , gsAvailability:[],setcontactState:null,deleteContactState:null,completedRequests:[],newtrackRequests:[],contactState:false,filterData:null,completedSafe: false,newTrackSafe: false,userSafe:false,gsSafe:false,passSafe:false,TMData:[],selectedCommData:[],SatTestData:[],userPermissions:null,cliTokens:null,passbookloader:false,passdeleteloader:false,TMHABData:[]};

export default function v1PreProcessorReducer(state = initState, action) {
  switch (action.type) {
     case v1actions.SELECTED_COMMAND_EDIT_PAGE:
       return{
         ...state,
         selectedCommData:action.payload,
       };
       case v1actions.GOT_SATELLITE_META:
        return{
          ...state,
          satellite_object:action.satellite_object,
        };
       case v1actions.GOT_SAT_TEST_DATA:
        return {
          ...state,
          SatTestData: action.SatTestData,
        };
     case v1actions.GOT_TM_DATA:
        return {
          ...state,
          TMData: action.TMData,
        };

        case v1actions.GOT_TM_HAB_DATA:
        return {
          ...state,
          TMHABData: action.TMHABData,
        };

    case v1actions.GOT_CONFIG:

        return {
          ...state,
          configData: action.configData,
        };
    case v1actions.CONFIG_SAFE:

      return {
        ...state,
        configSafe: true,
      };

      case v1actions.GOT_PASS_PREDICTION_DROPDOWN:

      return {
        ...state,
        passPredicitonDropdown: action.passPredictionDropdown,
      };
      case v1actions.PASS_SAFE:

      return {
        ...state,
        passSafe: true,
      };

      case v1actions.GS_SAFE:

        return {
          ...state,
          gsSafe: true,
        };
  

      case v1actions.GOT_COMPLETED_REQUESTS:
        
        return {
          ...state,
          completedRequests: action.completedRequests,
        };


      case v1actions.GOT_NEW_TRACK_REQUESTS:
        
        return {
          ...state,
          newtrackRequests: action.newtrackRequests,
        };

        case v1actions.GOT_USER_DETAILS:
        
        return {
          ...state,
          userDetails: action.userDetails,
        };


      case v1actions.CONTACT_SUCCESS:

        return {
          ...state,
          setcontactState: action.type,
        };


      case v1actions.CONTACT_FAILURE:

        return {
          ...state,
          setcontactState: action.type,
        };


      case v1actions.DELETE_PASS_FAILURE:

        return {
          ...state,
          deletedContactState: false,
        };

        case v1actions.COMPLETED_REQUESTS_SAFE:

        return {
          ...state,
          completedSafe: true,

          
        };


        case v1actions.USER_DETAILS_SAFE:

        return {
          ...state,
          userSafe:true,
          
        };

        case v1actions.NEW_TRACK_REQUESTS_SAFE:

        return {
          ...state,
          newTrackSafe: true,
         
        };


      case v1actions.DELETE_PASS_SUCCESS:

        return {
          ...state,
          deletedContactState: true,
        };


      case v1actions.GOT_USER_PERMISSIONS:

          return {
            ...state,
            userPermissions: action.userPermissions,
          };

          case v1actions.GOT_CLIENT_API_TOKENS:

          return {
            ...state,
            cliTokens: action.cliTokens,
          };
      

          case v1actions.PASS_BOOK_LOADER:
            return {
              ...state,
              passbookloader:action.passbookloader
            };

            case v1actions.PASS_DELETE_LOADER:
              return {
                ...state,
                passdeleteloader:action.passdeleteloader
              };



      case v1actions.GOT_GS_AVAILABILITY:

        return {
          ...state,
          gsAvailability: action,
        };


      case v1actions.FILTERING_DATA:

        return {
          ...state,
          filterData: action,
        };

      case v1actions.CONTACT_SUCCESS:

      return {
        ...state,
        contactState: true,
      };


      case v1actions.CONTACT_FAILURE:

       return {
        ...state,
        contactState: false,
      };


     default:
      
      return {...state};
  }
}
