import {
  all,
  takeEvery,
  put,
  call,
  fork,
  select,
  take,
} from 'redux-saga/effects';
import v1actions from './actions';
import actions from '../auth/actions';
import { eventChannel } from 'redux-saga';
import { rsf, db } from '@iso/lib/firebase/firebase';
import {
  getDBRef,
  getUserDetails,
  getpushkey,
} from '@iso/lib/firebase/firebase.util';
import firebase from 'firebase/app';
import UserModel from '../../library/groundstations/UserModel';
import GroundStationModel from '../../library/groundstations/GroundStationModel';
import SSCModel from '../../library/groundstations/SSCModel';
require('firebase/database');
import { notification } from '@iso/components';

function createSatTestEventChannel(req_id) {
  const listener = eventChannel((emit) => {
    getDBRef(
      `Users/${getUserDetails().uid}/Sat_Test_Forwarder/${req_id.payload}`
    )
      .limitToLast(100)
      .on('value', (data) => emit(data.val()));

    return () =>
      getDBRef(`Users/${getUserDetails().uid}/TM_Forwarder/${req_id}`).off(
        listener
      );
  });

  return listener;
}

function createHABTesTEventChannel(req_id) {
  const listener = eventChannel((emit) => {
    getDBRef(
      `Users/xqka4lszmtfVi3NnfVSqQf03J5l2/HAB_TELEMETRY/-MvqvUeqLLFDDjjFQb8-`
    )
      .limitToLast(10)
      .on('value', (data) => emit(data.val()));

    return () =>
      getDBRef(`Users/${getUserDetails().uid}/TM_HAB/${req_id}`).off(listener);
  });

  return listener;
}

function createTMForwarderEventChannel(req_id) {
  console.log(req_id.payload, 'req id is');
  const listener = eventChannel((emit) => {
    getDBRef(`Users/${getUserDetails().uid}/TM_Forwarder/${req_id.payload}`)
      .limitToLast(100)
      .on('value', (data) => emit(data.val()));

    return () =>
      getDBRef(`Users/${getUserDetails().uid}/TM_Forwarder/${req_id}`).off(
        listener
      );
  });

  return listener;
}

function createConfigEventChannel() {
  const listener = eventChannel((emit) => {
    getDBRef(`Users/${getUserDetails().uid}/satellite_configs`).on(
      'value',
      (data) => emit(data.val())
    );

    return () =>
      getDBRef(`Users/${getUserDetails().uid}/satellite_configs`).off(listener);
  });

  return listener;
}

export function* getUserData() {
  yield takeEvery('GET_USER_DETAILS', function* (payload) {
    let temp = payload['token'];
    let path = `/Users/${temp}`;
    try {
      const data = yield firebase.database().ref(path).once('value');

      yield all([
        put({
          type: v1actions.GET_PASS_PREDICTION_DROPDOWN,
          networkAccess: data.val(),
        }),
        put({ type: v1actions.GOT_USER_DETAILS, userDetails: data.val() }),
      ]);
    } catch (error) {
      let message;
      message = error.message;
    }
  });
}

export function* getPassPredicitionDropDowns() {
  yield takeEvery(v1actions.GET_PASS_PREDICTION_DROPDOWN, function* (payload) {
    const req2 = yield call(GroundStationModel.CRDropdowns, null);
    let reqData = req2;
    console.log(reqData.APIKeys);

    console.log(reqData.userPermissions, 'User permissions', reqData, 'req2');
    yield all([
      put({
        type: v1actions.GOT_CLIENT_API_TOKENS,
        cliTokens: reqData.APIKeys,
      }),
      put({
        type: v1actions.GOT_GS_AVAILABILITY,
        GSStateData: reqData.gsAvailability,
      }),
      put({
        type: v1actions.GOT_SATELLITE_META,
        satellite_object: reqData.Satellite_Meta_Information,
      }),
      put({
        type: v1actions.GOT_PASS_PREDICTION_DROPDOWN,
        passPredictionDropdown: reqData.dropdowns,
      }),
      put({
        type: v1actions.GOT_USER_PERMISSIONS,
        userPermissions: reqData.userPermissions,
      }),
    ]);
  });
}

export function* gotPassPredictionDropdown() {
  yield takeEvery(v1actions.GOT_PASS_PREDICTION_DROPDOWN, function* (payload) {
    try {
      yield put({ type: v1actions.PASS_SAFE });
    } catch (error) {}
  });
}

export function* gotGSAvailability() {
  yield takeEvery(v1actions.GOT_GS_AVAILABILITY, function* (payload) {
    try {
      yield put({ type: v1actions.GS_SAFE });
    } catch (error) {}
  });
}

export function* userPermissions() {
  yield takeEvery(v1actions.GOT_USER_PERMISSIONS, function* (payload) {
    try {
      yield put({
        type: v1actions.GOT_USER_PERMISSIONS,
        userPermissions: payload.payload,
      });
    } catch (error) {}
  });
}

export function* updateGSAvailability() {
  yield takeEvery(v1actions.UPDATE_GS_AVAILABILITY, function* (payload) {
    const data = (state) => state.v1.gsAvailability;
    const dataMain = yield select(data);
    try {
      let tempData = dataMain.GSStateData;
      let temp = tempData[payload.data.GS_id].reservedSlots;
      temp[
        payload.data.pass_start_time +
          '~' +
          payload.data.end_time +
          '~' +
          payload.data.norad_id
      ] = 1;
      tempData[payload.data.GS_id].reservedSlots = temp;
      yield put({ type: v1actions.GOT_GS_AVAILABILITY, GSStateData: tempData });
    } catch (error) {}
  });
}

export function* getCompletedRequests() {
  yield takeEvery(v1actions.GET_COMPLETED_REQUESTS, function* (payload) {
    try {
      const request = yield call(GroundStationModel.getCompletedRequests);
      yield put({
        type: v1actions.GOT_COMPLETED_REQUESTS,
        completedRequests: request,
      });
    } catch (error) {}
  });
}

export function* getNewTrackRequests() {
  yield takeEvery(v1actions.GET_NEW_TRACK_REQUESTS, function* (payload) {
    try {
      const request = yield call(GroundStationModel.getNewTrackRequests);
      yield put({
        type: v1actions.GOT_NEW_TRACK_REQUESTS,
        newtrackRequests: request,
      });
    } catch (error) {
      notification('error', 'failed to get data');
    }
  });
}

export function* deletePasses() {
  yield takeEvery(v1actions.DELETE_PASS, function* (payload) {
    console.log(payload.payload.element);
    yield put({
      type: v1actions.PASS_DELETE_LOADER,
      passdeleteloader: true,
    });
    try {
      const request = yield call(
        GroundStationModel.deleteContact,
        payload.payload.element
      );

      yield put({
        type: v1actions.GOT_GS_AVAILABILITY,
        GSStateData: request.gsAvailability,
      });
      yield put({
        type: v1actions.DELETE_PASS_SUCCESS,
        payload: payload.payload.element,
      });
    } catch (error) {
      yield put({
        type: v1actions.PASS_DELETE_LOADER,
        passdeleteloader: false,
      });
      yield put({ type: v1actions.DELETE_PASS_FAILURE });
    }
  });
}

export function* deletePassFromStore() {
  yield takeEvery(v1actions.DELETE_PASS_SUCCESS, function* (payload) {
    console.log(payload);

    let mainPayload = payload.payload.req_id;
    const data = (state) => state.v1.newtrackRequests;
    const availabilty = (state) => state.v1.gsAvailability;
    const omit = (obj, arr) => {
      console.log(obj);
      console.log(obj[arr]);
      delete obj[arr];
      console.log(obj[arr]);

      return obj;

      // Object.keys(obj)
      //   .filter((k) => !arr.includes(k))
      //   .reduce((acc, key) => ((acc[key] = obj[key]), acc), {});
    };
    const dataMain = yield select(data);
    const dataMain2 = yield select(availabilty);

    let temp = dataMain[mainPayload];
    let GS = dataMain[mainPayload]['GS_id'];
    console.log(
      dataMain[mainPayload],
      temp,
      GS,
      'inside delete pass from store'
    );
    let deletion =
      temp['pass_start_time'] + '~' + temp['end_time'] + '~' + temp['norad_id'];
    let newData = omit(dataMain, mainPayload);
    console.log(newData, 'new data');
    let toDelete = dataMain2.GSStateData;

    let newData2 = omit(toDelete[GS].reservedSlots, deletion);

    toDelete[GS].reservedSlots = newData2;
    // if(loltemp==dataMain2.GSStateData){
    //
    // }
    // else{
    //
    // }
    // yield put({
    //   type: v1actions.GOT_GS_AVAILABILITY,
    //   GSStateData: toDelete,
    // });
    yield put({
      type: v1actions.GOT_NEW_TRACK_REQUESTS,
      newtrackRequests: newData,
    });
    yield put({
      type: v1actions.PASS_DELETE_LOADER,
      passdeleteloader: false,
    });
    notification('success', 'Pass Deleted');
  });
}

export function* editPasses() {
  yield takeEvery(v1actions.EDIT_PASSES, function* (payload) {
    let payloadData = payload.payload.obj;
    const data = (state) => state.v1.newtrackRequests;
    let key = payloadData[1];
    let sampleRate = payloadData[0][0];
    let bandwidth = payloadData[0][1];

    const dataMain = yield select(data);

    try {
      const request = yield call(
        GroundStationModel.editNewTrackRequests,
        key,
        sampleRate,
        bandwidth
      );
      console.log(dataMain[key]);
      dataMain[key].bandwidth = bandwidth;
      dataMain[key].sampleRate = sampleRate;
      yield put({
        type: v1actions.GOT_NEW_TRACK_REQUESTS,
        newtrackRequests: dataMain,
      });
      notification('success', 'Pass Edited');
    } catch (error) {}
  });
}

///              TESTER SAGA FUNCTIONS             ///

export function* gotCompletedRequests() {
  yield takeEvery(v1actions.GOT_COMPLETED_REQUESTS, function* (payload) {
    try {
      yield put({ type: v1actions.COMPLETED_REQUESTS_SAFE });
    } catch (error) {}
  });
}

export function* gotUsers() {
  yield takeEvery(v1actions.GOT_USER_DETAILS, function* (payload) {
    yield put({ type: v1actions.USER_DETAILS_SAFE });
  });
}

export function* gotNewTrackRequests() {
  yield takeEvery(v1actions.GOT_NEW_TRACK_REQUESTS, function* (payload) {
    yield put({ type: v1actions.NEW_TRACK_REQUESTS_SAFE });
  });
}

export function* setContact() {
  yield takeEvery(v1actions.SET_CONTACT, function* (payload) {
    try {
      yield put({
        type: v1actions.PASS_BOOK_LOADER,
        passbookloader: true,
      });
      console.log(payload.payload.obj);
      const request = yield call(
        GroundStationModel.setContact,
        payload.payload.obj
      );

      console.log(request);
      if (request.pass_book_status == true) {
        const request_newtrack = yield call(
          GroundStationModel.getNewTrackRequests
        );
        const userPermissions_ = (state) => state.v1.userPermissions;
        const userPermissions = yield select(userPermissions_);
        console.log(userPermissions);
        let pendingPassSlots = userPermissions.pendingPassSlots;
        let updatedPassSlots = pendingPassSlots - 1;
        let newPermissions = {
          ...userPermissions,
          pendingPassSlots: updatedPassSlots,
        };

        yield put({
          type: v1actions.GOT_USER_PERMISSIONS,
          userPermissions: newPermissions,
        });
        yield put({
          type: v1actions.GOT_NEW_TRACK_REQUESTS,
          newtrackRequests: request_newtrack,
        });

        yield put({
          type: v1actions.CONTACT_SUCCESS,
          payload: { gsAvailability: request.gsAvailability },
        });
      }
    } catch (error) {
      yield put({ type: v1actions.CONTACT_FAILURE });
    }
  });
}

export function* updateContactinStore() {
  yield takeEvery(v1actions.CONTACT_SUCCESS, function* (payload) {
    yield put({
      type: v1actions.PASS_BOOK_LOADER,
      passbookloader: false,
    });
    const data = (state) => state.v1.newtrackRequests;

    // let key = payload.payload.newtrack.obj['req_id']
    // let value = payload.payload.newtrack.obj
    const dataMain = yield select(data);

    // dataMain[key]=value
    yield put({
      type: v1actions.GOT_GS_AVAILABILITY,
      GSStateData: payload.payload.gsAvailability,
    });
    // yield put({
    //   type: v1actions.UPDATE_GS_AVAILABILITY,
    //   data: payload.payload,
    // });
    //  yield put({
    //   type: v1actions.GOT_NEW_TRACK_REQUESTS,
    //   newtrackRequests: dataMain,
    // });
    notification('success', 'Pass Booked');
  });
}
export function* addConfig() {
  yield takeEvery(v1actions.ADD_CONFIG, function* (payload) {
    console.log(payload);

    try {
      const request = yield call(UserModel.addConfig, payload.payload.obj);
      console.log(request);
      if (request == true) {
        notification('success', 'Configuration Added');
        // yield put({ type:v1actions.ADD_CONFIG_SUCCESS,payload:payload.payload.obj });
      }
    } catch (error) {
      console.log(error);

      notification('error', 'Error in Configuration Addition');
      // yield put({ type: v1actions.ADD_CONFIG_FAILURE });
    }
  });
}

export function* updateConfig() {
  yield takeEvery(v1actions.UPDATE_CONFIG, function* (payload) {
    let req_id = payload.payload.obj[1];
    let data = payload.payload.obj[0];
    console.log(data, req_id, payload);
    try {
      const request = yield call(UserModel.updateConfigs, data, req_id);
      console.log(request);
      if (request == true) {
        notification('success', 'Configuration Updated Successfully');
        // yield put({ type:v1actions.ADD_CONFIG_SUCCESS,payload:payload.payload.obj });
      }
    } catch (error) {
      console.log(error);
      notification('error', 'Error in Configuration Updation');
      // yield put({ type:v1actions.ADD_CONFIG_FAILURE });
    }
  });
}

export function* deleteConfig() {
  yield takeEvery(v1actions.DELETE_CONFIG, function* (payload) {
    try {
      const request = yield call(UserModel.deleteConfigs, payload.payload.obj);

      if (request == true) {
        // yield put({ type: v1actions.ADD_CONFIG_SUCCESS,payload:payload.payload.obj });
      }
    } catch (error) {
      // yield put({ type: v1actions.ADD_CONFIG_FAILURE });
    }
  });
}
export function* getConfigs() {
  yield takeEvery(v1actions.GET_CONFIG, function* (payload) {
    const updateChannel = createConfigEventChannel();
    while (true) {
      const item = yield take(updateChannel);

      let temp_ = Object.values(item);
      temp_.forEach((element) => {
        if (element == 1) {
          temp_.pop(element);
        }
      });

      //  let _p = payload['passPredictionDropdown'];
      //
      yield put({ type: v1actions.GOT_CONFIG, configData: temp_ });
    }
    // try {
    //   let _p = payload['passPredictionDropdown'];
    //   const request = yield call(GroundStationModel.getGSAvailabilty, _p);
    //
    //   yield put({ type: v1actions.GOT_GS_AVAILABILITY, GSStateData: request });
    // } catch (error) {
    //
    // }
  });
}

export function* gotConfigs() {
  yield takeEvery(v1actions.GOT_CONFIG, function* (payload) {
    try {
      yield put({ type: v1actions.CONFIG_SAFE });
    } catch (error) {}
  });
}
// export function* filterData() {
//   yield takeEvery(v1actions.FILTERING_DATA, function* (payload) {

//
//   });
// }

export function* getSatTest() {
  yield takeEvery(v1actions.GET_SAT_TEST_DATA, function* (payload) {
    const updateChannel = createSatTestEventChannel(payload);
    while (true) {
      const item = yield take(updateChannel);

      let temp_ = Object.values(item);
      temp_.forEach((element) => {
        if (element == 1) {
          temp_.pop(element);
        }
      });

      //  let _p = payload['passPredictionDropdown'];
      //
      yield put({ type: v1actions.GOT_SAT_TEST_DATA, SatTestData: item });
    }
    // try {
    //   let _p = payload['passPredictionDropdown'];
    //   const request = yield call(GroundStationModel.getGSAvailabilty, _p);
    //
    //   yield put({ type: v1actions.GOT_GS_AVAILABILITY, GSStateData: request });
    // } catch (error) {
    //
    // }
  });
}

export function* getTM() {
  yield takeEvery(v1actions.GET_TM_DATA, function* (payload) {
    const updateChannel = createTMForwarderEventChannel(payload);
    while (true) {
      const item = yield take(updateChannel);
      console.log(item, 'TM Packet is');

      let temp_ = Object.values(item);
      temp_.forEach((element) => {
        if (element == 1) {
          temp_.pop(element);
        }
      });

      //  let _p = payload['passPredictionDropdown'];
      //
      yield put({ type: v1actions.GOT_TM_DATA, TMData: item });
    }
    // try {
    //   let _p = payload['passPredictionDropdown'];
    //   const request = yield call(GroundStationModel.getGSAvailabilty, _p);
    //
    //   yield put({ type: v1actions.GOT_GS_AVAILABILITY, GSStateData: request });
    // } catch (error) {
    //
    // }
  });
}

export function* getTMHAB() {
  yield takeEvery(v1actions.GET_TM_HAB_DATA, function* (payload) {
    const updateChannel = createHABTesTEventChannel(payload);
    while (true) {
      const item = yield take(updateChannel);
      console.log(item, 'HAB TM Packet is');

      let temp_ = Object.values(item);
      temp_.forEach((element) => {
        if (element == 1) {
          temp_.pop(element);
        }
      });

      //  let _p = payload['passPredictionDropdown'];
      //
      yield put({ type: v1actions.GOT_TM_HAB_DATA, TMHABData: item });
    }
    // try {
    //   let _p = payload['passPredictionDropdown'];
    //   const request = yield call(GroundStationModel.getGSAvailabilty, _p);
    //
    //   yield put({ type: v1actions.GOT_GS_AVAILABILITY, GSStateData: request });
    // } catch (error) {
    //
    // }
  });
}

export function* sendCommand() {
  yield takeEvery(v1actions.SEND_TM_COMMAND, function* (payload) {
    let config_req_id = payload.payload.obj.payload[1];
    let data = payload.payload.obj.payload[0];
    let pass_req_id = payload.payload.obj.payload[2];

    try {
      const request = yield call(
        UserModel.sendTMCommands,
        data,
        config_req_id,
        pass_req_id
      );

      if (request == true) {
        notification('success', 'Telecommand Updated Successfully');
        // yield put({ type: v1actions.ADD_CONFIG_SUCCESS,payload:payload.payload.obj });
      }
    } catch (error) {
      notification('error', 'Error in Telecommand Updation');
      // yield put({ type: v1actions.ADD_CONFIG_FAILURE });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getUserData),
    fork(getPassPredicitionDropDowns),
    fork(getCompletedRequests),
    fork(getNewTrackRequests),
    fork(gotPassPredictionDropdown),
    fork(gotGSAvailability),
    fork(setContact),
    fork(deletePasses),
    fork(deletePassFromStore),
    fork(editPasses),
    fork(updateContactinStore),
    fork(gotCompletedRequests),
    fork(gotNewTrackRequests),
    fork(gotUsers),
    fork(updateGSAvailability),
    fork(addConfig),
    fork(getConfigs),
    fork(gotConfigs),
    fork(updateConfig),
    fork(deleteConfig),
    fork(getTM),
    fork(sendCommand),
    fork(getSatTest),
    fork(getTMHAB),
    // fork(filterData),
  ]);
}
