import React from 'react';
import { Provider } from 'react-redux';
import GlobalStyles from '@iso/assets/styles/globalStyle';
import { store } from './redux/store';
import Boot from './redux/boot';
import Routes from './router';
import AppProvider from './AppProvider';
import * as Cesium from 'cesium';
import {Cartesian3,Ion} from "cesium";

// 
// let userPermissions = useSelector((state) => state.v1.userPermissions);
// function applySideEffects(){
//   let userPermissions = useSelector((state) => state.v1.userPermissions);
//   console.log(userPermissions)
//   return Ion.defaultAccessToken="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI1YTU5OTJlNi1hOTExLTQyZGYtYWMyNC1iOTIwYmQxNWY0MmIiLCJpZCI6NjUxMzUsImlhdCI6MTYyOTg4NDkyNH0.lXZblYKaur7sbfc3wMplYi-WkuYuFCMQZG2_qsWmvAU"
// }



const App = () =>{
  return (

  
  <Provider store={store}>
  
    <AppProvider>
      <>
      
        <GlobalStyles />
        <Routes />
      </>
    </AppProvider>
  </Provider>
)};
Boot()
  .then(() => App())
  .catch(error => console.error(error));

export default App;
