import {
  all,
  takeEvery,
  put,
  fork,
  call,
  delay,
  take,
  actionChannel,
  takeLatest,
  select,
} from 'redux-saga/effects';
import { createBrowserHistory } from 'history';
import { rsf, auth, sessionID } from '@iso/lib/firebase/firebase';
import { getToken, clearToken } from '@iso/lib/helpers/utility';
import actions from './actions';
import v1actions from '../preProcessingv1/actions';
import { notification } from '@iso/components';
import firebase from 'firebase/app';
require('firebase/auth');
const history = createBrowserHistory();
import { getUserDetails } from '../../library/firebase/firebase.util';
const fakeApiCall = true; // auth0 or express JWT

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function* ({ payload }) {
    try {
      const data = yield call(
        rsf.auth.signInWithEmailAndPassword,
        payload.token[0],
        payload.token[1]
      );

      // yield put({
      //   type: actions.LOGIN_SUCCESS,
      //   token: data.user.uid,
      //   profile: 'Profile',
      // });
      yield all([
        put({
          type: v1actions.GET_USER_DETAILS,
          token: data.user.uid,
          profile: 'Profile',
        }),
        put({ type: v1actions.GET_COMPLETED_REQUESTS, token: data.user.uid }),
        put({ type: v1actions.GET_NEW_TRACK_REQUESTS, token: data.user.uid }),
        put({ type: v1actions.GET_CONFIG }),
        yield put({
          type: actions.LOADER,
          data: true,
        }),
      ]);
      // produces error on login. check!
      // const transmitterList = yield call(GroundStationModel.getTransmitterList);
      // const satelliteList = yield call(GroundStationModel.getSatelliteList);
      // yield put({
      //   type: v1actions.GOT_SATELLITE_,
      //   completedRequests: 4,
      // });
      yield all([
        take([v1actions.GS_SAFE]),
        take([v1actions.PASS_SAFE]),
        take([v1actions.NEW_TRACK_REQUESTS_SAFE]),
        take([v1actions.COMPLETED_REQUESTS_SAFE]),
        take([v1actions.CONFIG_SAFE]),
      ]);

      var uid = getUserDetails() != null ? getUserDetails().uid : null;

      yield put({
        type: actions.LOADER,
        data: false,
      });
      yield put({
        type: actions.LOGIN_SUCCESS,
        token: uid,
        profile: 'Profile',
      });

      // yield all([
      //   take([v1actions.USER_DETAILS_SAFE]),
      //   take([v1actions.NEW_TRACK_REQUESTS_SAFE]),
      //   take([v1actions.COMPLETED_REQUESTS_SAFE]),
      // ]);

      // const channel1 = yield actionChannel(v1actions.USER_DETAILS_SAFE);
      // const channel2 = yield actionChannel(v1actions.NEW_TRACK_REQUESTS_SAFE);
      // const channel3 = yield actionChannel(v1actions.COMPLETED_REQUESTS_SAFE);
      // while (true){
      //   try{
      //     yield all([take(channel1),take(channel2),take(channel3)])

      //   }
      //   catch{

      //   }

      // }

      // yield put({
      //   type: 'GET_USER_DETAILS',
      //   token: data.user.uid,
      //   profile: 'Profile',
      //           });
      //  yield put({
      //   type: actions.LOGIN_SUCCESS,
      //   token: data.user.uid,
      //   profile: 'Profile',
      // });
    } catch (error) {
      yield put({ type: actions.LOGIN_ERROR });
    }

    const { token } = payload;
    // if (token) {
    //   yield put({
    //     type: actions.LOGIN_SUCCESS,
    //     token: token,
    //     profile: 'Profile',
    //   });
    // } else {
    //   if (fakeApiCall) {

    //     yield put({
    //       type: actions.LOGIN_SUCCESS,
    //       token: 'secret token',
    //       profile: 'Profile',
    //     });
    //   } else {
    //     yield put({ type: actions.LOGIN_ERROR });
    //   }
    // }
  });
}

export function* tester() {
  // yield all([v1actions.USER_DETAILS_SAFE, v1actions.NEW_TRACK_REQUESTS_SAFE, v1actions.COMPLETED_REQUESTS_SAFE], function* () {
  //   var uid = getUserDetails() != null ? getUserDetails().uid : null;
  //   yield put({
  //   type: actions.LOGIN_SUCCESS,
  //   token: uid,
  //   profile: 'Profile',
  // });
  //
  // });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
    // yield localStorage.setItem('id_token', payload.token);
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () {
    notification('error', 'Wrong Password');
  });
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    try {
      yield call([firebase.auth(), firebase.auth().signOut]);
    } catch (error) {}

    // history.push('/dashboard');
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const token = getToken().get('idToken');
    if (token) {
      // yield put({
      //   type: actions.LOGIN_SUCCESS,
      //   token,
      //   profile: 'Profile',
      // });
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(tester),
    fork(logout),
  ]);
}
