import actions from '../auth/actions';

const v1actions = {
  GOT_USER_PERMISSIONS:'GOT_USER_PERMISSIONS',
  GOT_CLIENT_API_TOKENS:'GOT_CLIENT_API_TOKENS',
  GOT_SATELLITE_META:'GOT_SATELLITE_META',
  GET_USER_DETAILS: 'GET_USER_DETAILS',
  GOT_USER_DETAILS: 'GOT_USER_DETAILS',
  GET_PASS_PREDICTION_DROPDOWN: 'GET_PASS_PREDICTION_DROPDOWN',
  GOT_PASS_PREDICTION_DROPDOWN: 'GOT_PASS_PREDICTION_DROPDOWN',
  PASS_SAFE:'PASS_SAFE',
  GET_COMPLETED_REQUESTS: 'GET_COMPLETED_REQUESTS',
  GOT_COMPLETED_REQUESTS: 'GOT_COMPLETED_REQUESTS',
  GET_NEW_TRACK_REQUESTS: 'GET_NEW_TRACK_REQUESTS',
  GOT_NEW_TRACK_REQUESTS: 'GOT_NEW_TRACK_REQUESTS',
  GS_AVAILABILITY: 'GS_AVAILABILITY',
  GOT_GS_AVAILABILITY: 'GOT_GS_AVAILABILITY',
  GS_SAFE:'GS_SAFE',
  SET_CONTACT: 'SET_CONTACT',
  CONTACT_SUCCESS: 'CONTACT_SUCCESS',
  CONTACT_FAILURE: 'CONTACT_FAILURE',
  DELETE_PASS: 'DELETE_PASS',
  DELETE_PASS_SUCCESS: 'DELETE_PASS_SUCCESS',
  DELETE_PASS_FAILURE: 'DELETE_PASS_FAILURE',
  EDIT_PASSES: 'EDIT_PASSES',
  COMPLETED_REQUESTS_SAFE:'  COMPLETED_REQUESTS_SAFE',
  NEW_TRACK_REQUESTS_SAFE:'NEW_TRACK_REQUESTS_SAFE',
  USER_DETAILS_SAFE:'USER_DETAILS_SAFE',
  UPDATE_GS_AVAILABILITY:'UPDATE_GS_AVAILABILITY',
  FILTERING_DATA:'FILTERING_DATA',
  GET_CONFIG:'GET_CONFIG',
  GOT_CONFIG:'GOT_CONFIG',
  CONFIG_SAFE:'CONFIG_SAFE',
  ADD_CONFIG:'ADD_CONFIG',
  UPDATE_CONFIG:'UPDATE_CONFIG',
  DELETE_CONFIG:'DELETE_CONFIG',
  GET_TM_DATA:'GET_TM_DATA',
  GOT_TM_DATA:'GOT_TM_DATA',
  GET_SAT_TEST_DATA:'GET_SAT_TEST_DATA',
  GOT_SAT_TEST_DATA:'GOT_SAT_TEST_DATA',
  SEND_TM_COMMAND:'SEND_TM_COMMAND',
  SELECTED_COMMAND_EDIT_PAGE:'SELECTED_COMMAND_EDIT_PAGE',
  PASS_BOOK_LOADER:'PASS_BOOK_LOADER',
  PASS_DELETE_LOADER:'PASS_DELETE_LOADER',
  GOT_TM_HAB_DATA:'GOT_TM_HAB_DATA',
  GET_TM_HAB_DATA:'GET_TM_HAB_DATA',
  updatedSelectedCommand:(element)=>({
    type:v1actions.SELECTED_COMMAND_EDIT_PAGE,
    payload:{element}
  }),
  deleteElement: (element) => ({
    type: v1actions.DELETE_PASS,
    payload: { element },
  }),
  filteringData:(data)=>({
    type:v1actions.FILTERING_DATA,
    payload:{data},
  }),
  sendCommand:(obj)=>({type:v1actions.SEND_TM_COMMAND,payload:{obj}}),
  getTMHABdata:(obj)=>({type:v1actions.GET_TM_HAB_DATA,payload:{obj}}),
  getTMdata:(obj)=>({type:v1actions.GET_TM_DATA,payload:{obj}}),
  setContact: (obj) => ({ type: v1actions.SET_CONTACT, payload: { obj } }),
  editPasses: (obj) => ({ type: v1actions.EDIT_PASSES, payload: { obj } }),
  updateGSavailability:(obj)=>({type: v1actions.UPDATE_GS_AVAILABILITY, payload: { obj }}),
  addConfig:(obj)=>({type:v1actions.ADD_CONFIG,payload:{obj}}),
  updateConfig:(obj)=>({type:v1actions.UPDATE_CONFIG,payload:{obj}}),
  deleteConfig:(obj)=>({type:v1actions.DELETE_CONFIG,payload:{obj}})
};
export default v1actions;
