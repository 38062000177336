import { getUserData } from '@iso/redux/preProcessingv1/saga';
import {
  getDBRef,
  getUserDetails,
  getpushkey,
  getStorageRef,
  getSessionDetails,
} from '../../library/firebase/firebase.util';

import streamSaver from 'streamsaver';

// const path = require('path');
// const cwd = path.join(__dirname, '..');
// var Storage = require('@google-cloud/storage');
var axios = require('axios');
var cros = 'http://127.0.0.1:8084/';
import * as stream from 'stream';

import firebase from 'firebase/app';
require('firebase/database');
require('firebase/storage');
var proxy_server = 'https://proxy.dhruvaspace.xyz:1456/';
//https://v1isocs-vavtjz6emq-uc.a.run.app
// Imports the Google Cloud client library

// Creates a client

// https://proxy.dhruvaspace.xyz:1456/https://v1isocs-vavtjz6emq-uc.a.run.app/hellotofirebase
export default class GroundStationModel {
  // static checkForUser(onComplete){
  //     var uid = Firebase.getUserDetails()!=null ? Firebase.getUserDetails().uid :null ;
  //     if(uid==null){
  //         onComplete('nouser') ;
  //     }else{
  //         onComplete('userLogined');
  //     }
  // }
  static async CRDropdowns() {
    let sessionID = await getSessionDetails();
    let uuid = await getUserDetails().uid;
    console.log(sessionID);
    var config = {
      method: 'POST',
      mode: 'no-cors',
      url: `${proxy_server}https://v1isocs-vavtjz6emq-uc.a.run.app/networkAccess`,
      data: uuid,
      headers: {
        Authorization: `Bearer ${sessionID}`,
      },
    };

    try {
      let dataMain = null;
      await axios(config).then((d) => {
        dataMain = d.data;
      });
      console.log(dataMain);

      return dataMain;
    } catch (error) {
      let dataMain = [];
      return dataMain;
    }
  }

  static async setContact(obj) {
    var uid = getUserDetails() != null ? getUserDetails().uid : null;

    let sessionID = await getSessionDetails();

    console.log(obj, 'obj to server is');
    //for loop of each obj
    var config = {
      method: 'POST',
      mode: 'no-cors',
      url: `${proxy_server}https://v1isocs-vavtjz6emq-uc.a.run.app/setContact`,
      data: { uuid: uid, contact: obj },
      headers: {
        Authorization: `Bearer ${sessionID}`,
      },
    };

    try {
      let dataMain = null;
      await axios(config).then((d) => {
        dataMain = d.data;
      }); // make to list
      console.log(dataMain, dataMain.reference_contact[0]);
      dataMain.reference_contact.forEach((pass) => {
        getDBRef(`Users/${uid}/new_track_requests`).update(pass);
      });
      console.log(dataMain);
      return dataMain;
    } catch (error) {
      let dataMain = [];
    }

    return dataMain;
  }

  static async getCompletedRequests() {
    var uid = getUserDetails() != null ? getUserDetails().uid : null;
    let dataMain = await getDBRef(
      `Users/${getUserDetails().uid}/completed_requests`
    ).once('value');
    return dataMain.val();
  }

  static async getNewTrackRequests(callback) {
    var uid = getUserDetails() != null ? getUserDetails().uid : null;
    let dataMain = await getDBRef(
      `Users/${getUserDetails().uid}/new_track_requests`
    ).once('value');
    return dataMain.val();
  }

  static async editNewTrackRequests(id, sampleRate, bandwidth) {
    var uid = getUserDetails() != null ? getUserDetails().uid : null;
    let sessionID = await getSessionDetails();
    let dataMain = await getDBRef(
      `Users/${getUserDetails().uid}/new_track_requests/`
    )
      .child(id)
      .update({ sampleRate: sampleRate, bandwidth: bandwidth });
    var config = {
      method: 'POST',
      mode: 'no-cors',
      url: `${proxy_server}https://v1isocs-vavtjz6emq-uc.a.run.app/editContact`,
      data: {
        uuid: uid,
        contact: { key: key, sampleRate: sampleRate, bandwidth: bandwidth },
      },
      headers: {
        Authorization: `Bearer ${sessionID}`,
      },
    };

    try {
      let dataMain = null;
      await axios(config).then((d) => {
        dataMain = d.data;
      });
      console.log(dataMain);
      return dataMain;
    } catch (error) {
      let dataMain = [];
    }
    return true;
  }
  static async deleteContact(element) {
    var uid = getUserDetails() != null ? getUserDetails().uid : null;
    let sessionID = await getSessionDetails();
    // let tletemp=[46490,
    //   40024,	47947,	47945,	47926,47314,	47311,47309,	46932,46922,46495,46493,	46491,,	46312,46287,	46274,43186,43158,	43155,	43138,	43132,	43116,	43018,43017,	43016,	42983,42829,	42794,42792,42791,	39438,	22825,	21575,	40024]
    //
    // let temp_data=await getDBRef('GroundStationInfoMeta/DSBEGUMPET/SATELLITE/').once('value');
    //
    // let k=Object.values(temp_data.val())
    //
    // const unique = Array.from(new Set(k));
    //
    // let lol=await getDBRef('GroundStationInfoMeta/DSBEGUMPET/').child('SATELLITE').remove();
    // unique.forEach(async element=>{
    //      let tempp=await getDBRef('GroundStationInfoMeta/DSBEGUMPET/SATELLITE/').push(element)
    // })
    // tletemp.forEach(async element=>{
    //   let tempp=await getDBRef('GroundStationInfoMeta/DSBEGUMPET/SATELLITE/').push(element)

    // })
    // for(i=0;i<tletemp.length;i++){
    //
    // let tempp=await getDBRef('GroundStationInfoMeta/DSBEGUMPET/SATELLITE/').update(tletemp[i])
    // }
    console.log(element);

    var config = {
      method: 'POST',
      mode: 'no-cors',
      url: `${proxy_server}https://v1isocs-vavtjz6emq-uc.a.run.app/deleteContact`,
      data: { uuid: uid, contact: element },
      headers: {
        Authorization: `Bearer ${sessionID}`,
      },
    };

    try {
      let dataMain = null;
      await axios(config).then((d) => {
        dataMain = d.data;
      });
      console.log(dataMain);
      console.log(element.req_id);
      await getDBRef(`Users/${getUserDetails().uid}/new_track_requests`)
        .child(element.req_id)
        .remove();

      return dataMain;
    } catch (error) {
      let dataMain = [];
    }

    return dataMain;
  }

  static async tempDownload() {
    var db = firebase.storage();

    let url = await db
      .ref(`/Users/xqka4lszmtfVi3NnfVSqQf03J5l2/-Mt3O1MAARmRAgT5o4dw.wav`)
      .getDownloadURL();
    console.log(url);
    url = `${proxy_server}${url}`;

    const fileStream = streamSaver.createWriteStream('lol.wav');

    fetch(url).then((res) => {
      const readableStream = res.body;

      // more optimized
      if (window.WritableStream && readableStream.pipeTo) {
        return readableStream
          .pipeTo(fileStream)
          .then(() => console.log('done writing'));
      }

      window.writer = fileStream.getWriter();

      const reader = res.body.getReader();
      const pump = () =>
        reader
          .read()
          .then((res) =>
            res.done ? writer.close() : writer.write(res.value).then(pump)
          );

      pump();
    });

    //   window.open(url)

    // const { data } = await axios.get(url, {
    //   responseType: 'arraybuffer',
    //   headers: {
    //       'Content-Type': 'audio/wav'
    //   }
    // });
    // console.log(data)
    //   const blob = new Blob([data], {
    //     type: 'audio/wav'
    // });
    // const url_ = window.URL.createObjectURL(blob);
    // const link = document.createElement('a');
    // link.href = url_;
    // link.setAttribute('download', 'file.wav'); //or any other extension
    // document.body.appendChild(link);
    // link.click();
    // console.log(blob)
  }
  static async downloadFile(id) {
    const options = {
      destination: '/',
    };

    // Downloads the file
    // await storage.bucket('dhruvaspacecontrolcenter.appspot.com').file(`/Users/${getUserDetails().uid}/${id+'.wav' }`).download(options);

    // console.log(
    //   `gs://dhruvaspacecontrolcenter.appspot.com/${fileName} downloaded to ${destFileName}.`
    // );
    var db = firebase.storage();
    db.ref(`/Users/${getUserDetails().uid}/${id + '.wav'}`)
      .getDownloadURL()
      .then((url) => {
        // `url` is the download URL for 'images/stars.jpg'
        console.log(url);
        url = `${proxy_server}${url}`;
        const fileStream = streamSaver.createWriteStream(`${id}.wav`);

        fetch(url).then((res) => {
          const readableStream = res.body;

          // more optimized
          if (window.WritableStream && readableStream.pipeTo) {
            return readableStream
              .pipeTo(fileStream)
              .then(() => console.log('done writing'));
          }

          window.writer = fileStream.getWriter();

          const reader = res.body.getReader();
          const pump = () =>
            reader
              .read()
              .then((res) =>
                res.done ? writer.close() : writer.write(res.value).then(pump)
              );

          pump();
        });
        // // This can be downloaded directly:
        // var xhr = new XMLHttpRequest();
        // xhr.responseType = 'blob';
        // xhr.onload = (event) => {
        //   var blob = xhr.response;
        // };
        // xhr.open('GET', url);
        // xhr.send();
      })
      .catch((error) => {
        // Handle any errors
      });

    // var aa = await getStorageRef(`/Users/${getUserDetails().uid}/${id+'.wav' }`).getDownloadURL()

    // return window.open(aa, '_blank');
  }
}
