import notification from '@iso/components/Feedback/Notification';
import { getUserData } from '@iso/redux/preProcessingv1/saga';
import {
  getDBRef,
  getUserDetails,
  getpushkey,


  getStorageRef
} from '../../library/firebase/firebase.util';
// const path = require('path');
// const cwd = path.join(__dirname, '..');

export default class UserModel {


  static async addConfig(data) {
      var key = getpushkey();
      data['req_id'] = key;

      var newobjj = { [key]: data };
      console.log('newobjj',newobjj)
    var uid = getUserDetails() != null ? getUserDetails().uid : null;
    try{

      let dataMain = await getDBRef(
        `Users/${getUserDetails().uid}/satellite_configs/`
      ).update(newobjj);
    }
    catch(error){
      console.log(error)
      return false
   
      
    }
    
    return true
    
  }

  static async updateConfigs(data,req_id) {
  
  var uid = getUserDetails() != null ? getUserDetails().uid : null;
  console.log(req_id,data,uid)
  try{
    // let dataMain = await getDBRef(
    //   `Users/${getUserDetails().uid}/satellite_configs/${req_id}`
    // ).update(data);
    let dataMain = await getDBRef(
      `Users/${uid}/satellite_configs/${req_id}`
    ).update({config_description:data.config_description,config_name:data.config_name,items:data.items,noradId:'44999',anon_func:data.anon_func,commanditems:data.commanditems,time:new Date(),req_id:req_id});

    return true
  }
  catch(error){
console.log(error)
return false
  }

  
}

static async sendTMCommands(data,config_req_id,pass_req_id) {
  var uid = getUserDetails() != null ? getUserDetails().uid : null;
  var key = getpushkey();
  var newobjj = { [key]: {commands:data,config_id:config_req_id,user:uid,pass_id:pass_req_id,req_id:key} };
  try{
    let dataMain = await getDBRef(
      `Users/${getUserDetails().uid}/TMCommands/`
    ).update(newobjj);

    // notification("success","Telecommand Sent")


    return true
  }
  catch(error){

// notification("error","Could not send TeleCommand")
return false
  }

  
}

static async deleteConfigs(req_id) {
    
    var uid = getUserDetails() != null ? getUserDetails().uid : null;
    let dataMain = await getDBRef(
      `Users/${getUserDetails().uid}/satellite_configs/`
    ).child(req_id).remove();
    return true
    
  }

  

}
